import React from 'react';
import { GetStaticPropsContext } from 'next';

import { FB_APP_ID } from '@constants/config';
import { FooterLinksProvider } from '@hooks/useFooterLinks';
import { DestinationLinksProvider } from '@hooks/useDestinationLinks';
import { SiteLinksProvider } from '@hooks/useSiteLinks';
import Layout from '@components/layout/Layout/Layout';
import { CookieDialog } from '@components/forms';
import LoginPage from '@components/pages/LoginPage/LoginPage';
import { PageProps } from '@constants/types';
import { ILoginPublicationFields, LoginModule } from 'modules/LoginModule';
import GenericService from '@services/GenericService';
import GraphqlService from '@services/GraphqlService';
import MailchimpService from '@services/MailchimpService';
import PersonalService from '@services/PersonalService';

export const { FacebookProvider } = require('react-facebook');

const Login = ({
  fields,
  siteLinks,
  footerLinks,
  destinationLinks,
}: PageProps<ILoginPublicationFields>) => {
  return (
    <FacebookProvider appId={FB_APP_ID} xfbml>
      <FooterLinksProvider value={footerLinks}>
        <DestinationLinksProvider value={destinationLinks}>
          <SiteLinksProvider value={siteLinks}>
            <Layout paddings>
              <LoginPage fields={fields} />
            </Layout>
            <CookieDialog />
          </SiteLinksProvider>
        </DestinationLinksProvider>
      </FooterLinksProvider>
    </FacebookProvider>
  );
};

export default Login;

export const getStaticProps = async ({ locale, preview }: GetStaticPropsContext) => {
  GraphqlService.setLocale(locale);
  PersonalService.setLocale(locale);
  GenericService.setLocale(locale);
  MailchimpService.setLocale(locale);

  GraphqlService.setPreview(!!preview);

  const page = await LoginModule.getSinglePage();

  return {
    props: page,
  };
};
