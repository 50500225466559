import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';

import styles from './LoginPage.module.scss';
import { useStores } from '@models/RootStore/RootStore';
import LoginFormWrapper from '@components/forms/LoginFormWrapper/LoginFormWrapper';
import RegisterFormWrapper from '@components/forms/RegisterFormWrapper/RegisterFormWrapper';
import { Meta } from '@components/layout/Meta/Meta';
import FacebookLoginButton from '@components/ui/FacebookLoginButton/FacebookLoginButton';
import GoogleLoginButton from '@components/ui/GoogleLoginButton/GoogleLoginButton';
import { ILoginPublicationFields } from 'modules/LoginModule';

interface Props {
  fields: ILoginPublicationFields;
}

const LoginPage = ({ fields }: Props) => {
  const { login_title, login_subtitle, register_title, register_subtitle } = fields;
  const { UserStore } = useStores();
  const router = useRouter();

  useEffect(() => {
    if (UserStore.hasLoaded) {
      if (UserStore.isAuthenticated) {
        router.replace(fields.myAccountSlug);
      }
    }
  }, [UserStore.hasLoaded]);

  return (
    <>
      <Meta
        title={fields.meta_title || `${login_title} / ${register_title}`}
        description={fields.meta_description || `${login_subtitle} ${register_subtitle}`}
      />
      <div className={styles.LoginPage}>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className={styles.HeaderContainer}>
                <h2 className="mb-xs">{login_title}</h2>
                <span>{login_subtitle}</span>
              </div>
              <LoginFormWrapper />
              <div className={styles.SocialLogins}>
                <FacebookLoginButton className={styles.FacebookLogin} />
                <GoogleLoginButton className={styles.GoogleLogin} />
              </div>
            </div>
            <div className="col-md-6">
              <div className={styles.HeaderContainer}>
                <h2 className="mb-xs">{register_title}</h2>
                <span>{register_subtitle}</span>
              </div>

              <RegisterFormWrapper />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default observer(LoginPage);
